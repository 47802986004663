import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../CssFiles/loginModal.css";
import { getMobAndGlid, fullLogin, setFullLoginCookie , getLocalStorageWithExpiration, setLocalStorageWithExpiration, gatrack, eventBus, setLoginStatCookie, setEncrGLID} from "../../utils";

const OtpPopup = ({ phoneNumber, handleOtpClose }) => {
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [timer, setTimer] = useState(30);
    const [isTimerRunning, setIsTimerRunning] = useState(true);
    const [glid, setGlid] = useState('');
    const [mob, setMob] = useState('');
    const [otpWarning, setOtpWarning] = useState(false);
    const [codeAlreadySent, setCodeAlreadySent] = useState(false);

     const clicktracking =(glid)=> {
        var host = window.location.hostname;
    
        if(host == 'loans.indiamart.com'){
            var getlenderorderURL = "https://loans.indiamart.com/functions.php";
        }
        else{
            var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
        }
    
       
            var getlenderorderData = {
                "glid" : glid,
                "functionName" : "StoreLenderOrderData",
                "lenderorder" : "NA",
                "action" : "OTP Modal Sign In Button Clicked",
                "ApplyNow" : "",
                "device" : "Desktop" 
            };
        
    
        var formData = new FormData();
        for (var key in getlenderorderData) {
            formData.append(key, getlenderorderData[key]);
        }
    
        if(glid)
        {
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: getlenderorderURL,
                data: formData,
                timeout:10000
                }).then(function (response) {
                })
        }
    }

    const handleSubmit = async() => {

        //console.group("glid, mob, otp => ", glid, mob, otp);
        let otpStr = otp.join('');
        if(glid && mob && otpStr.length == 4){
            let sendOtpResp = await fullLogin(glid, mob, "OTPVer", otpStr);
            //console.log("Response from full login otp ver => ", sendOtpResp);
            if(sendOtpResp.data && sendOtpResp.data.Response.Code == "200"){
                //console.log("OTP verified successfully");
                if(sendOtpResp.data.Response.LOGIN_DATA && sendOtpResp.data.Response.LOGIN_DATA.DataCookie && sendOtpResp.data.Response.LOGIN_DATA.im_iss){
                    await setEncrGLID(glid,sendOtpResp.data.Response.LOGIN_DATA.im_iss.t)
                    setLoginStatCookie("true");
                    await setFullLoginCookie(sendOtpResp.data.Response.LOGIN_DATA.DataCookie, sendOtpResp.data.Response.LOGIN_DATA.im_iss);
                    eventBus.emit("doneLogin", { message: "true" });
                    clicktracking(glid);
                    gatrack('Partially Logged-in Desktop User','Sign In Pressed','Click',glid);
                    handleOtpClose("ver");
                }
            } else if(sendOtpResp.data && sendOtpResp.data.Response.Code == "204"){
                //console.log("Wrong OTP")
                setOtpWarning(true);
            }
          } else{
            if(otpStr.length != 4){
                //console.log("incomplete OTP")
                setOtpWarning(true);
            } else{
                //console.log("glid or mob is missing");
                handleDiffUser();
            }
          }
    };

    const handleChange = (value, index) => {
        if (isNaN(value)) return; // Allow only numeric input
        const newOtp = [...otp];
        newOtp[index] = value;

        setOtp(newOtp);
        setOtpWarning(false);

        // Move to the next input automatically if a value is entered
        if (value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData("text").slice(0, 4); // Get only 4 digits
        const newOtp = [...otp];
        for (let i = 0; i < pastedData.length; i++) {
            if (i < otp.length) newOtp[i] = pastedData[i];
        }
        setOtp(newOtp);
        setOtpWarning(false);

        // Automatically focus the last non-empty box
        const lastIndex = Math.min(pastedData.length - 1, otp.length - 1);
        document.getElementById(`otp-input-${lastIndex}`).focus();
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            const newOtp = [...otp];

            // Clear the current box and move focus to the previous box
            if (otp[index] === "") {
                if (index > 0) {
                    document.getElementById(`otp-input-${index - 1}`).focus();
                    newOtp[index - 1] = "";
                }
            } else {
                newOtp[index] = "";
            }

            setOtp(newOtp);
        } else if (e.key === "Enter" && otp.join("").length === 4) {
            handleSubmit(); 
        }
    };

    const handleResendClick = () => {
        if (!isTimerRunning) {
            sendOtp();
            setIsTimerRunning(true);
            setTimer(30);
        }
    };

    const sendFirstOtp = async() =>{
        
    var details = await getMobAndGlid();
      //console.log(" details from mob and glid => ", details);
      if(details.glid && details.mob){
        setGlid(details.glid);
        setMob(details.mob);
        if(getLocalStorageWithExpiration("otp") == details.mob){
            setCodeAlreadySent(true);
            return;
        } else{
            setCodeAlreadySent(false);
        }
        let sendOtpResp = await fullLogin(details.glid, details.mob, "OTPGen", "");
        //console.log("Response from full login otp gen => ", sendOtpResp);
        if(sendOtpResp.data && sendOtpResp.data.Response.Code == "200"){
            //console.log("OTP sent successfully");
            setLocalStorageWithExpiration("otp",details.mob,20);
        } 
      } else{
        handleDiffUser();
      }
    }

    const handleDiffUser = () =>{
        handleOtpClose("diffUser");
    }

    const handleCloseBtn = () =>{
        handleOtpClose("close");
    }

    const sendOtp = async() =>{
      if(glid && mob){
        if(getLocalStorageWithExpiration("otp") == mob){
            setCodeAlreadySent(true);
            return;
        } else{
            setCodeAlreadySent(false);
        }
        let sendOtpResp = await fullLogin(glid, mob, "OTPGen", "");
        //console.log("Response from full login otp gen => ", sendOtpResp);
        if(sendOtpResp.data && sendOtpResp.data.Response.Code == "200"){
            //console.log("OTP re-sent successfully");
            setLocalStorageWithExpiration("otp",mob,20);
        }
      } else{
        sendFirstOtp();
      }
    }

    useEffect(() => {
        let interval = null;

        if (isTimerRunning && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setIsTimerRunning(false);
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timer, isTimerRunning]);

    useEffect(() => {
        sendFirstOtp();
    }, []);

    return (
        <div className="otp-popup">
            <div className="otp-container">
                <div className="close-btn"><i className="bi bi-x" style={{fontSize:'35px', display:'ruby-text', cursor:'pointer'}} onClick={handleCloseBtn}></i> </div>
                <h2>Enter OTP</h2>
                <p style={{ color: '#117b00', fontWeight: '500' }}>
                    Code {codeAlreadySent && 'already '}sent to{' '}
                    {mob.includes("*") 
                        ? mob 
                        : `${mob.substring(0, 3)}${'*'.repeat(Math.max(0, mob.length - 5))}${mob.substring(mob.length - 2)}`}
                </p>

                <p id='diff-user' onClick={handleDiffUser}>Sign In as Different User</p>
                <div className="otp-inp-parent">
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            id={`otp-input-${index}`}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handleChange(e.target.value, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={handlePaste}
                            className="otp-box"
                        />
                    ))}

                </div>
                <p className="otp-resend">
                    Didn't receive code? <br />
                    <span
                        onClick={handleResendClick}
                        style={{ color: isTimerRunning ? "black !important" : "#2563EB", textDecoration: 'none', cursor: isTimerRunning ? "default" : "pointer" }}
                    >
                        {isTimerRunning ? (
                            <>
                                <span>Request Again in</span>
                                <span style={{ width: "80px", marginLeft: "5px" }}>
                                    (00:{timer.toString().padStart(2, "0")})
                                </span>
                            </>
                        ) : (
                            "Resend OTP"
                        )}
                    </span>
                </p>
                {otpWarning && <div style={{fontSize:'13px', color:'red', position:'absolute', positionArea:'center', marginTop:'22px'}}> Please enter correct 4 digit code.</div>}
                <button className="otp-verify-btn" onClick={handleSubmit}>
                    Sign In
                </button>
            </div>
        </div>
    );
};

export default OtpPopup;
