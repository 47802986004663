import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';

import {getGlid, geturldata, glidtracker, getGlidEnd, getGstData, checkLoginStatus, fullLoginIds, eventBus, getMobAndGlid } from '../../utils';

import moneyicon from '../../Images/headerImages/money-icon.svg';
import menuIcon from '../../Images/headerImages/menu-icon.svg';
import ArrowIcon from '../../Images/headerImages/filterDropdown.svg'; 
import sad_face from '../../Images/applicationsImages/sad_face.png';
import OtpPopup from '../common/OtpVerComponent';
import OtpVerComponent from '../common/OtpVerComponent'
import SendOtpComponent from '../common/SendOtpComponent';
import ThanksComponent from '../common/ThanksComponent';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const glidEnd = getGlidEnd();

const headerStyle = {
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor:'#FFF',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: '200'
};

const logoStyle = {
    maxWidth: '130px',
};

const moneyIconStyle = {
    float: 'right',
    marginTop: '5px',
    marginRight: '15px',
    width: '36px',
    height: '31px',
};

const menuIconStyle = {
    float: 'right',
    marginTop: '5px', marginRight: '15px', height: '32px', width: '32px'
}

const usernameStyle = {
    float: 'right',
    marginTop: '10px',
    marginRight: '15px',
    color: '#fff',
};


function getcookieready(dataarray) {
    const formattedString = Object.entries(dataarray)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');

    return formattedString;
}

function gatrack(category, action, label) {
    window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label});
}

function clicktracking (vendor_name,self,actionClick='',opt='',dt='',time='') {
    var glid = getGlid();
    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    if(actionClick == 'submit'){
        var getlenderorderData = {
            "glid" : glid,
            "functionName" : "StoreLenderOrderData",
            "lenderorder" : "NA",
            "action" : "Guidance Popup Submit Click",
            "ApplyNow" : "NA",
            "device" : "Desktop",
            "timeRequest": (opt == 'specific') ? dt + " " + time + " " : "Anytime" 
        };
    }
    else{
        var getlenderorderData = {
            "glid" : glid,
            "functionName" : "StoreLenderOrderData",
            "lenderorder" : "NA",
            "action" : "Apply Now Click",
            "ApplyNow" : vendor_name,
            "device" : "Desktop" 
        };
    }
    

    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }
}

function clicktrackingCommon (vendor_name,lenderorder,action) {
    var glid = getGlid();

    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var getlenderorderData = {
        "glid" : glid,
        "functionName" : "StoreLenderOrderData",
        "lenderorder" : (lenderorder !== '') ? lenderorder : "NA",
        "action" : (action !== '') ? action : "NA",
        "ApplyNow" : vendor_name,
        "device" : "Desktop" 
    };

    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {   
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }
}


class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isDropdownVisible: false, 
          username : 'Hi,  User',
          showAppPage:false,
          applyNowVisible1:false,
          applyNowVisible2:false,
          applyNowVisible3:false,
          applyNowVisible4:false,
          applyNowVisible5:false,
          applyNowVisible6:false,
          vendor_name:'',
          vendor_url:'',
          url_selector:'Apply Now',
          guidanceBox:false,
          selectedTime:'10:00 AM',
          selectedDate:this.formatDate(tomorrow),
          selectedOption:'anytime',
          timeOpt:false,
          ggSubmit:false,
          dateOpt:false,
          tatacapitalCta:"Apply Now",
          oneinfinityCta:"Apply Now",
          indifiCta:"Apply Now",
          iiflCta:"Apply Now",
          loantapCta:"Apply Now",
          iciciCta:"Apply Now",
          lenderData:'',
          showReject:false,
          showRejectReason:'',
          rejectLenName:'',
          lenderDataState:'',
          currLen:'',
          lenderAppName:'',
          otpVer: false,
          sendOTP:false,
          mob:'', 
          showThanks:false,
        };
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.timeoutId = null;
      }

    resetTimer = () => {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(this.openPopup, 15000);
    };

    handleUserActivity = () => {
        this.resetTimer();
    };

    openPopup = () =>{
        if(!sessionStorage.getItem('guidanceVisit')){
            this.setState({guidanceBox:true});
            sessionStorage.setItem('guidanceVisit', 'true');
        }
    }

      handleOptionChange = (event) => {
        this.setState({timeOpt:false})
        this.setState({
          selectedOption: event.target.value
        });
      };

      generateOptionsDate() {
        const options = [];
        const today = new Date();
        
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        for (let i = 0; i < 5; i++) {
          const date = new Date(tomorrow);
          date.setDate(date.getDate() + i);
          options.push(this.formatDate(date));
        }
        
        return options;
    }
    
    formatDate(date) {
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }    
    
    handleSelectChangeDate = (event) => {
        this.setState({ selectedDate: event.target.value });
        this.setState({timeOpt:false});
    };

    generateOptionsTime() {
        const options = [];
        for (let hour = 10; hour <= 12; hour++) {
            if(hour == 12){
                options.push(`${hour}:00 PM`);
                options.push(`${hour}:30 PM`);
            }
            else{
                options.push(`${hour}:00 AM`);
                options.push(`${hour}:30 AM`);
            }
        }
        for (let hour = 1; hour <= 6; hour++) {
            options.push(`${hour}:00 PM`);
            if(hour != 6){
                options.push(`${hour}:30 PM`);
            }
        }
        return options;
    }

    handleSelectChangeTime = (event) => {
        this.setState({ selectedTime: event.target.value });
    };

    openGuidance = () => {
        this.setState({selectedOption:'anytime'})
        this.setState({selectedTime:'10:00 AM'})
        this.setState({selectedDate:this.formatDate(tomorrow)})
        var glid = getGlid();
        this.setState({timeOpt:false});
        if(glid){
            this.setState({guidanceBox:true})
            this.setState({selectedOption:'anytime'})
            if(!sessionStorage.getItem('guidanceVisit', 'true')){
                sessionStorage.setItem('guidanceVisit', 'true');
            }
        }
        else{
            this.setState({show:true});
            this.setState({ggSubmit:true});
        }
    }

    handleLoginCheck = async() =>{
        let loginStatus = await checkLoginStatus();
        let glid = getGlid();
        //console.log("Login Status => ", loginStatus);
            if(loginStatus == "partial"){
                // this.setState({otpVer:true});
                this.setState({sendOTP:true})
            } else{
                this.openGuidance();
            }
    }

    toggleDropdown = (event) => {
        event.stopPropagation();
        this.setState({isDropdownVisible:true});
    };

    applyNowtoggle = (ctr) => {
        this.setState({applyNowVisible1:false});
        this.setState({applyNowVisible2:false});
        this.setState({applyNowVisible3:false});
        this.setState({applyNowVisible4:false});
        this.setState({applyNowVisible5:false});
        this.setState({applyNowVisible6:false});
        if(ctr == '1'){
            this.setState({applyNowVisible1:true});
        }
        else if(ctr == '2'){
            this.setState({applyNowVisible2:true});
        }
        else if(ctr == '3'){
            this.setState({applyNowVisible3:true});
        }
        else if(ctr == '4'){
            this.setState({applyNowVisible4:true});
        }
        else if(ctr == '5'){
            this.setState({applyNowVisible5:true});
        }
        else if(ctr == '6'){
            this.setState({applyNowVisible6:true});
        }
    };

    userStatus(glid){
        return new Promise((resolve) => {
            var showAppPage = false;
    
            var host = window.location.hostname;
    
            var statusUrl = host == 'loans.indiamart.com' ? 'https://loans.indiamart.com/functions.php' : 'https://dev-loans.indiamart.com/functions.php';
    
            var statusData = {
                "functionName": "checkStatus",
                "glid": glid,
            };
    
            var formData = new FormData();
            for (var key in statusData) {
                formData.append(key, statusData[key]);
            }
    
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: statusUrl,
                data: statusData,
                timeout: 10000
            }).then((response) => {
                //console.log("response in header=> ",response['data']);
                if (response['data'] == true) {
                    //console.log("showAppPage setting true");
                    showAppPage = true;
                }
                //console.log("Finally showAppPage in header=> ", showAppPage);
                resolve(showAppPage);
            }).catch(error => {
                console.error("Error occurred: ", error);
                // Resolve with false if an error occurs
                resolve(false);
            });
        });
    }

    checkApplication = (glid,lender,trigger='') =>{
        var host = window.location.hostname;
        if (host == 'loans.indiamart.com') {
            var checkAppURL = "https://loans.indiamart.com/functions.php";
        }
        else {
            var checkAppURL = "https://dev-loans.indiamart.com/functions.php";
        }

        var checkAppData = {
            "glid": glid,
            "lender":lender,
            "functionName": "checkApplication",
            // "respFlag":'1'
        };

        var formData = new FormData();
        for (var key in checkAppData) {
            formData.append(key, checkAppData[key]);
        }

        var self = this;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: checkAppURL,
            data: formData,
            timeout: 10000
        }).then((response) => {
            var vendor_name = lender;
            var vendor_url = this.state.vendor_url;
            this.setState({vendor_url:false});
            if (response.data == true) {
                this.state.url_selector = "Continue";
                //console.log("Url selector=>",url_selector);
                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" || vendor_name == "Indifi Business Loan" || vendor_name == "IIFL Business Loan")
                {
                    glidtracker(vendor_name, "Continue");
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity")
                {
                    geturldata(vendor_name, "Continue");
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click'); 
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(product_name,self);                            
                }
                else
                {
                    if(vendor_name == "LendingKart Business Loan"){
                        vendor_url = "https://www.lendingkart.com/dashboard/auth";
                    }
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                    window.open(vendor_url,'_blank');
                } 

            }
            else{
                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" || vendor_name == "Indifi Business Loan" || vendor_name == "IIFL Business Loan")
                {
                    glidtracker(vendor_name, "Apply Now");
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity")
                {
                    geturldata(vendor_name, "Apply Now");
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(product_name,self);;                            
                }
                else
                {
                    gatrack('Apply for loan',vendor_name,'Click');
                    (trigger == 'appAny') ? clicktrackingCommon(vendor_name,'','Apply Anyway Click') : clicktracking(vendor_name,self);
                    window.open(vendor_url,'_blank');
                }
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    submitbtnClick = async() => {
        const phoneNumberInput = document.getElementById('phoneNumber').value;

        var vendor_name = this.state.vendor_name;
        var show = this.state.show;

        this.setState({vendor_name:false});


        gatrack('Non Logged-in User','Submit Button Pressed','Click',phoneNumberInput);

        if(!phoneNumberInput)
        {
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';

            document.getElementById("mobile_err").style.display = 'block';
            document.getElementById("phoneNumber").style.borderColor = 'red';
        }
        else if(phoneNumberInput.length != 10)
        {
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';

            document.getElementById("mobile_err2").style.display = 'block';
            document.getElementById("phoneNumber").style.borderColor = 'red';
        }
        else if(! ['6', '7', '8', '9'].includes(phoneNumberInput.charAt(0)))
        {
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';
            
            document.getElementById("mobile_err3").style.display = 'block';
            document.getElementById("phoneNumber").style.borderColor = 'red';
        }
        else{
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';
            document.getElementById("phoneNumber").style.borderColor = '';

            
            var host = window.location.hostname;

            if(host == 'loans.indiamart.com'){
                var identifyurl = 'https://loans.indiamart.com/functions.php';
            }
            else{
                var identifyurl = 'https://dev-loans.indiamart.com/functions.php';
            }

            //var identifyurl = 'https://localhost:8000/functions.php';

            var identifyData = {
                "functionName" :"identify",
                "mobile" : phoneNumberInput
            };

            var formData = new FormData();
            for (var key in identifyData) {
                formData.append(key, identifyData[key]);
            }

            var self = this;

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: identifyurl,
                data: formData,
                timeout:10000
              }).then(async (response) => {
                var data_obj = response['data'];

                
                if(data_obj.code == '200') {
                    
                    var glid = data_obj.DataCookie.glid;
                    var cookiestring = getcookieready(data_obj.DataCookie);

                    const expirationDate = new Date();
                    expirationDate.setDate(expirationDate.getDate() + 30);

                    const cookie = new Cookies();

                    if(host == 'loans.indiamart.com'){
                        cookie.set('ImeshVisitor', cookiestring, { path: '/',  domain: '.indiamart.com', expires: expirationDate });
                    } else{
                        cookie.set('ImeshVisitor', cookiestring, { path: '/', expires: expirationDate });
                    }
                    

                    var visitor_name = data_obj.DataCookie.fn;

                    // if(visitor_name) {

                    //     document.getElementById('username').textContent = 'Hi, ' + visitor_name;
                    // }

                    self.setState({show:false});
                    //if(fullLoginIds.has(glid)){
                        this.setState({otpVer:true})
                    // } else{
                    //     self.setState({guidanceBox:true})
                    //     eventBus.emit("doneLogin", { message: "true" });
                    // }
                    
                    
                    // if(self.state.ggSubmit){
                    //     self.setState({guidanceBox:true})
                    //     // clicktracking('','','submit',this.state.selectedOption,this.state.selectedDate,this.state.selectedTime,this.setState.bind(this));;
                    // }
                    // else{
                    //     self.checkApplication(glid, vendor_name);
                    // }
                    
                }
              })

        }    
    
    };

    toggleDate = () => {
        this.setState({timeOpt:false})
        if(this.state.dateOpt){
            this.setState({dateOpt:false})
        }
        else{
            this.setState({dateOpt:true})
        }
    }
    
    handleDropClickDate = (date) => {
        this.setState({selectedDate:date})
        this.setState({dateOpt:false})
    }
    

    handleCloseClick = () => {
        if(this.state.show){
            this.setState({show:false})
        }
    }

    checkLender(glid){
            // var goApiList = ['132160368','13035342','195143359'];

            var host = window.location.hostname;
            if (host == 'loans.indiamart.com') {
                var checkLenderURL = "https://loans.indiamart.com/api/v1/checkLender";
            }
            else {
                var checkLenderURL = "https://dev-loans.indiamart.com/api/v1/checkLender";
            }
    
            var checkLenderData = {
                "glid": glid,
                // "respFlag":'1'
            };
    
            var formData = new FormData();
            for (var key in checkLenderData) {
                formData.append(key, checkLenderData[key]);
            }
    
            var self = this;
    
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: checkLenderURL,
                data: JSON.stringify(checkLenderData),
                timeout: 10000
            }).then((response) => {
                if (response.data != false) {
                    const lenderArray = response.data;
                    if (lenderArray.includes('Indifi')) {
                        this.setState({ indifiCta: "Continue App." });
                    }
                    if (lenderArray.includes('IIFL')) {
                        this.setState({ iiflCta: "Continue App." });
                    }
                    if (lenderArray.includes('ICICI')) {
                        this.setState({ iciciCta: "Continue App." })
                    }
                    if (lenderArray.includes('LoanTap')) {
                        this.setState({ loantapCta: "Continue App." })
                    }
                    if (lenderArray.includes('Tata Capital')) {
                        this.setState({ tatacapitalCta: "Continue App." })
                    }
                    if (lenderArray.includes('OneInfinity')) {
                        this.setState({ oneinfinityCta: "Continue App." })
                    }
                }
            })
    }

    async gstApiNewFunc(glid){
        

            var data_obj = await getGstData(glid);
            var gstNo = '', gstStatus = '';
    
            if (data_obj['code'] == '200') {
                if (data_obj['data']['values']) {

                    if(data_obj['data']['values']['gstin_number']);
                    {
                        gstNo = data_obj['data']['values']['gstin_number'];
                    }

                    if(data_obj['data']['values']['gstin_status']);
                    {
                        gstStatus = data_obj['data']['values']['gstin_status'];
                    }
                }
            }

            var result = {gstNo:gstNo, gstStatus:gstStatus}
            return result;
        
    }

    async componentDidMount() {
        const cookie = new Cookies();
        // cookie.set('ImeshVisitor',"fn=Komal|em=d*******@gmail.com|phcc=91|iso=IN|mb1=8118804003|ctid=73648|glid=195143359|cd=03/MAY/2023|cmid=10|utyp=P|ev=V|uv=V|usts=|admln=0|admsales=0");
        var visitor_name = '', glid = '';

        let visitorData = cookie.get('ImeshVisitor');
        if (visitorData) {
            visitorData = visitorData.split("|");

            let sd = [];
            visitorData.map((data) => {
                sd = data.split("=")
                if (sd[0] == "fn") {
                    visitor_name = sd[1]
                }
                if (sd[0] == "glid") {
                    glid = sd[1]
                }
            })
        }

        // if (glid) {
        //     try {
        //         // Fetch user
        //         const flag = await this.userStatus(glid);
        //         this.setState({ showAppPage: flag });
        //         //console.log("In header comp");
        //     } catch (error) {
        //         console.error("Error fetching user status: ", error);
        //         // Handle error//
        //     }
        //     this.checkLender(glid);
        // }


        if (visitor_name) {
            this.setState({username : 'Hi, ' + visitor_name});
        }
        
        if (navigator.userAgent.includes("IM-Android")) {
            document.getElementById("im_header").style.backgroundColor = "#00a699";
        }

        var order = localStorage.getItem("lenderOrder");
        if(order !== undefined && order !== null){
            this.setState({lenderData: order});
        }

        // var details = await getMobAndGlid();
        
        // if(details.glid && details.mob){
        //     this.setState({mob:details.mob})
        // }
        this.setAutoPopup(); 
    }

    async setAutoPopup(){
        let loginStatus = await checkLoginStatus()
        //console.log("Login Status => ", loginStatus);
        if(loginStatus == "full"){
        this.resetTimer();
            window.addEventListener('mousemove', this.handleUserActivity);
            window.addEventListener('mousedown', this.handleUserActivity);
            window.addEventListener('keypress', this.handleUserActivity);
            window.addEventListener('scroll', this.handleUserActivity);
        }
    }

    async removeAutoPopup(){
        let loginStatus = await checkLoginStatus()
        //console.log("Login Status => ", loginStatus);
        if (loginStatus == "full") {
            window.removeEventListener('mousemove', this.handleUserActivity);
            window.removeEventListener('mousedown', this.handleUserActivity);
            window.removeEventListener('keypress', this.handleUserActivity);
            window.removeEventListener('scroll', this.handleUserActivity);
            clearTimeout(this.timeoutId);
        }
    }

    async componentWillUnmount() {
        this.removeAutoPopup();
    }

    handleclick = (e) => {
        e.stopPropagation();
    }

    handleLogoClick = () => {
        gatrack('LOANS HomePage','IM Logo','Click');
    }

    // checkApplication(vendor_name, lender_url, lender){
    //     const cookie = new Cookies();

    //     var glid='';

    //     let visitorData = cookie.get('ImeshVisitor');
    //     if(visitorData)
    //     {
    //         visitorData = visitorData.split("|");
    
    //         let sd = [];
    //         visitorData.map((data) => { 
    //             sd = data.split("=")
    //             if(sd[0] == "glid"){
    //                 glid = sd[1]
    //             }
    //         })
    //     }

    //     var host = window.location.hostname;
    //     if (host == 'loans.indiamart.com') {
    //         var checkAppURL = "https://loans.indiamart.com/functions.php";
    //     }
    //     else {
    //         var checkAppURL = "https://dev-loans.indiamart.com/functions.php";
    //     }

    //     var checkAppData = {
    //         "glid": glid,
    //         "lender":lender,
    //         "functionName": "checkApplication",
    //         // "respFlag":'1'
    //     };

    //     var formData = new FormData();
    //     for (var key in checkAppData) {
    //         formData.append(key, checkAppData[key]);
    //     }

    //     var self = this;

    //     axios({
    //         method: 'post',
    //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    //         url: checkAppURL,
    //         data: formData,
    //         timeout: 10000
    //     }).then((response) => {
    //         if (response.data == true) {
    //             this.logincheck(vendor_name, lender_url, "Continue");
    //         }
    //         else{
    //             this.logincheck(vendor_name, lender_url, "Apply Now");
    //         }
    //     }).catch((error) => {
    //         console.error("Error:", error);
    //     });
    // }


    handleAppAnyClick = () => {
        var glid= getGlid(); 
        
        this.checkApplication(glid,this.state.lenderAppName,'appAny')
        this.setState({showReject:false})
    }

    handleExploreClick = () => {
        gatrack('Explore Other Offers',this.state.lenderAppName,'Click');
        this.setState({showReject:false});
        this.scrollToElement('lenders')
    }

    scrollToElement = (scrolledto) => {
        const element = document.getElementById(scrolledto);
        
        if (element) {
          const yOffset = element.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: yOffset - 60, behavior: 'smooth' });
        }
    };

    logincheck = async(vendor_name, vendor_url, url_selector) =>
    {
        var glid = getGlid();
        
        var self =  this;
        var order = JSON.parse(localStorage.getItem("lenderOrder"));
        for(var i = 0; i < order.length ;i++){
            if(order[i]['logincheck'] == vendor_name){
                var lenderData = order[i];
            }
        }
        if(!glid)
        {
            this.setState({show:true});
            this.setState({vendor_name:vendor_name});
            this.setState({currLen:lenderData})
            this.setState({vendor_url:vendor_url});
            this.setState({url_selector:url_selector});
            gatrack('Non Logged in User','Popup Opened','Click');
        }
        else
        {   
            this.setState({vendor_url:vendor_url});
            this.setState({lenderAppName:vendor_name});
            if(lenderData.image == 'oneinfinity'){
                var result = this.gstApiNewFunc(glid);
                var gstNo = result.gstNo;
                var gstStatus = result.gstStatus; 
            }

            if(lenderData['offertype_footer'] !== undefined && lenderData['offertype_footer'] !== null){
                var rejectReason = lenderData['offertype_footer'];
            }
            if((rejectReason === 'vintagefail' || rejectReason === 'turnoverfail' || rejectReason === 'pincodefail' || rejectReason === 'rejected' || ((gstStatus !== 'Active' || (gstNo != '' && gstNo.charAt(5) !== 'P')) && lenderData.image == 'oneinfinity')) && (lenderData['applynowbtn'] == 'appBtnApply' ||  lenderData['applynowbtn'] == 'appBtnApplyRejected')){

                this.setState({showReject:true});                   
                gatrack('Reject Popup',vendor_name,'Open');

                if(rejectReason === 'pincodefail'){        //1st
                    this.setState({showRejectReason:' does not service loan applications in your area.'});
                }
                else if(rejectReason === 'vintagefail'){         //2nd
                    this.setState({showRejectReason:'does not service loan applications in your age of business bracket.'});
                }
                else if(rejectReason === 'turnoverfail'){       //3rd
                    this.setState({showRejectReason:'does not service loan applications in your turnover range.'});
                }
                else if(lenderData.image == 'oneinfinity' && gstNo && gstNo.charAt(5) !== 'P'){       //4th
                    self.setState({showReject:true});
                    self.setState({showRejectReason:'serves only Proprietorship Companies.'});
                    self.setState({rejectLenName:'OneInfinity'})
                }
                else if(lenderData.image == 'oneinfinity' && gstStatus !== 'Active'){        //5th
                    self.setState({showReject:true});
                    self.setState({showRejectReason:'serves only customers with an Active GST.'});
                    self.setState({rejectLenName:'OneInfinity'})
                }
                else if(rejectReason === 'rejected'){       //6th
                    this.setState({showRejectReason:'recently rejected your loan application.'});
                }
                
                let lenderName = vendor_name.toString();
                lenderName = lenderName.replace("Business Loan", "").trim();
                
                this.setState({rejectLenName : lenderName});
            }
            else{
                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" ||  vendor_name == "Indifi Business Loan" || vendor_name == 'IIFL Business Loan')
                {
                    glidtracker(vendor_name, url_selector);
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name,self);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity")
                {
                    geturldata(vendor_name, url_selector);
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click');
                    clicktracking(product_name,self);                              
                }
                else
                {
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name,self);
                    window.open(vendor_url,'_blank');
                } 
            }
            // this.checkApplication(glid, vendor_name);
        }
        
    }

    handleThanks = () => {
        this.setState({showThanks:false})
    }

    handleSendOtpClose = (msg) => {
        //console.log("msg from sendOtp => ", msg);
        if(msg == "send"){
            this.setState({sendOTP:false, otpVer:true});
        } else if(msg == "diff"){
            this.setState({sendOTP:false, show:true});
        } else{
            this.setState({sendOTP:false});
        }
    }

    handleOtpScreen = (msg) => {
        if(msg == "ver"){
            this.setState({otpVer:false});
            //console.log("Closing otp screen, otp verified in intent form comp");
            this.openGuidance();
        } else if(msg == "diffUser"){
            this.setState({otpVer:false});
            this.setState({show:true});
            //console.log("Opening phone number modal in lender comp")
        }
        else{
            this.setState({otpVer:false});
            //console.log("Closing otp screen as user clicked cross button or some error occured");
        }
    }

    handleSubmitClick = () => {
        clicktracking('','','submit',this.state.selectedOption,this.state.selectedDate,this.state.selectedTime,this.setState.bind(this));
        this.setState({guidanceBox:false, showThanks:true}, () => {
            setTimeout(() => {
                this.setState({ showThanks: false });
            }, 6000);
        })
    }

    handleMouseEnter = () => {
        this.setState({applyNowVisible1:false});
        this.setState({applyNowVisible2:false});
        this.setState({applyNowVisible3:false});
        this.setState({applyNowVisible4:false});
        this.setState({applyNowVisible5:false});
        this.setState({applyNowVisible6:false});
    }

    toggleTime = () => {
        this.setState({dateOpt:false});
        if(this.state.timeOpt){
            this.setState({timeOpt:false})
        }
        else{
            this.setState({timeOpt:true});
        }
    }

    handleDropClick = (time) =>{
        this.setState({selectedTime:time})
        this.setState({timeOpt:false})
    }

    render() {
        const optionsTime = this.generateOptionsTime();
        const optionsDate = this.generateOptionsDate();
        return (
            <>
                <header id="im_header" style={headerStyle}>
                    <div className="container" style={{ maxWidth: '1010px', height: '58px' }}>
                        <div className="row" style={{ height: '100%' }}>
                            <div className="col-12" style={{ padding: '0' }}>
                                <div className="header" style={{ height: '100%',maxWidth:'1200px',margin:'0 auto',display:'flex' }}>
                                {
                                    window.location.pathname === '/' ?
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <a href="https://indiamart.com/" target="_blank" onClick={this.handleLogoClick} style={{margin:'auto 0px'}}>
                                            <img style={logoStyle} alt="im_logo" src={'https://loans.imimg.com/images/loans/headerimages/im_logo_new.png'} />
                                        </a>
                                        <div style={{border: '0.5px solid #BDBDBD',height:'20px',margin:'14px'}}></div>
                                            <img style={{width:'27px',height:'24px'}} alt="im_logo" src={'https://loans.imimg.com/images/loans/headerimages/hand_icon.png'} />
                                    </div> 
                                    :
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <a href="/" target="_blank" onClick={this.handleLogoClick} style={{margin:'auto 0px'}}>
                                            <img style={logoStyle} alt="im_logo" src={'https://loans.imimg.com/images/loans/headerimages/im_logo_new.png'} />
                                        </a>
                                        <div style={{border: '0.5px solid #BDBDBD',height:'20px',margin:'14px'}}></div>
                                            <img style={{width:'27px',height:'24px'}} alt="im_logo" src={'https://loans.imimg.com/images/loans/headerimages/hand_icon.png'} />
                                    </div> 
                                }

                                {/* <div style={{border: '0.5px solid #BDBDBD',margin:'10px'}}></div>

                                <img src={'https://loans.imimg.com/images/loans/headerimages/hand_icon.png'} alt='Money' style={{width:'27px',height:'24px',margin:'auto 0px'}}/> */}

                                <div className='headerBar'>
                                    {
                                        window.location.pathname === '/' ?
                                        <div className='headerContent' style={{fontWeight:'700'}}>
                                            <a href='/' style={{textDecoration:'none'}}>Home</a>
                                        </div> :
                                        <div className='headerContent'>
                                            <a href='/' style={{textDecoration:'none'}}>Home</a>
                                        </div>
                                    }
                                    {/* {
                                        window.location.pathname === '/applications' ?
                                        <div className='headerContent' style={{fontWeight:'700'}}>
                                            <a href='/applications' style={{textDecoration:'none'}}>Your Applications</a>
                                        </div> :
                                        this.state.showAppPage &&
                                        <div className='headerContent'>
                                            <a href='/applications' style={{textDecoration:'none'}}>Your Applications</a>
                                        </div>
                                    } */}
                                    {
                                        window.location.pathname === '/about' ?
                                        <div className='headerContent' style={{fontWeight:'700'}}>
                                            <a href='/about' style={{textDecoration:'none'}}>About Us</a>
                                        </div> :
                                        
                                        <div className='headerContent'>
                                            <a href='/about' style={{textDecoration:'none'}}>About Us</a>
                                        </div>
                                    }
                                    {
                                        window.location.pathname === '/product' ?
                                        <div className='headerContent' style={{fontWeight:'700'}}>
                                            <a href='/product' style={{textDecoration:'none'}}>Our Products</a>
                                        </div> :
                                        <div className='headerContent'>
                                            <a href='/product' style={{textDecoration:'none'}}>Our Products</a>
                                        </div>
                                    }
                                                                
                                
                                </div>

                                <div className='guidance' onClick={this.handleLoginCheck}>
                                    <img src={'https://loans.imimg.com/images/loans/headerimages/call_icon.png'} style={{width:'12.55px',height:'auto',marginRight:'7px',marginBottom:'1px'}}></img>
                                    Get Guidance</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                
                <Modal 

                        show={this.state.show}
                        backdrop="static"
                        keyboard={false}
                        id="loginpopup" style={{marginTop:'200px'}}>
                        
                        <Modal.Header style={{backgroundColor: '#007A6E', padding: '8px 16px'}}>
                            <Modal.Title className="fs-5" style={{color: '#ffff', fontWeight: '700', fontSize: '20px !important'}} >Sign In</Modal.Title>
                            <button type="button" className="custom-close-button" onClick={this.handleCloseClick} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: '0', position: 'absolute', top: '2px', right: '16px' }}>
                                &times;
                            </button>
                        </Modal.Header>
                            <Modal.Body >
                                <label htmlFor="phoneNumber" style={{marginLeft: '3px', marginBottom: '10px'}}>Mobile Number linked to IndiaMART account</label>
                                <input type="tel" maxLength="10" placeholder="Enter your IndiaMART account mobile number" id="phoneNumber" className="un2_s" style={{backgroundColor: 'rgb(255, 255, 255)', outline: 'none', boxSizing: 'content-box', paddingLeft: '5px', width: '97%', height: '30px', margin: 'auto',letterSpacing:'0px', fontSize:'14px'}} pattern="[0-9]{10}" required/>
                                <span id="mobile_err" className="em-1" style={{display: 'none', fontSize: '12px', marginLeft: '3px'}}>Please enter mobile number</span>
                                <span id="mobile_err2" className="em-1" style={{display: 'none', fontSize: '12px', marginLeft: '3px'}}>Please enter ten digit mobile number</span>
                                <span id="mobile_err3" className="em-1" style={{display: 'none', fontSize: '12px', marginLeft: '3px'}}>Mobile Number should start with 6,7,8 or 9</span>
                            <button id="submitBtn" className="continue_s" style={{padding: '8px 0px', width: '180px', backgroundColor: '#007A6E', margin: '15px auto',display:'block',marginBottom:'5px'}} onClick={this.submitbtnClick} >Submit</button>
        
                            </Modal.Body> 
                        
                    
                </Modal>

                <Modal 
                show={this.state.guidanceBox}
                // show={true}
                backdrop="static"
                keyboard={false}
                id="guidancepopup" style={{marginLeft:'-80px',marginTop:'100px'}}>
                    <div className='guidanceOuter'>
                        <div>
                            <img src={'https://loans.imimg.com/images/loans/headerimages/guidance_banner.png'} alt='guidance-banner' style={{maxHeight:'375px',maxWidth:'256px'}}/>
                        </div>
                        <div style={{minWidth:'405px',backgroundColor:'#FFF',borderRadius:'6px'}}>
                            <img src={'https://loans.imimg.com/images/loans/headerimages/cross_icon.png'} alt='cross' style={{width:'20px',height:'20px',float:'right',marginRight:'10px',marginTop:'10px',cursor:'pointer'}} onClick={()=>{this.setState({guidanceBox:false})}}/>

                            <div className='innerBoxGuidance'>
                                <div className='callback'>Request for Call Back</div>
                                <div className='struggleBox'>Struggling with your business loan application? <br/>Get a <span style={{color:'#02A699',fontWeight:'600'}}>Free Consultation.</span></div>
                            </div>

                            <hr></hr>
                            <div style={{marginLeft:'30px'}}>
                                <div style={{fontSize: '14px',fontWeight: '400',lineHeight: '19.6px',color: '#757575'}}>Select Your Preferred Call Time</div>

                                <label className="guidanceRadio">
                                    <input 
                                        type="radio" 
                                        id="anytime" 
                                        name="call_type" 
                                        value="anytime" 
                                        checked={this.state.selectedOption === 'anytime'} // Check if 'anytime' is selected
                                        onChange={this.handleOptionChange} 
                                    /> Anytime During business hours
                                </label> <br/>
                                
                                <label className="guidanceRadio"> 
                                    <input 
                                        type="radio" 
                                        id="specific" 
                                        name="call_type" 
                                        value="specific" 
                                        checked={this.state.selectedOption === 'specific'} // Check if 'specific' is selected
                                        onChange={this.handleOptionChange} 
                                    /> Select specific date & time
                                </label>
                                
                                {
                                    this.state.selectedOption == 'specific' &&
                                    <div style={{margin:'20px 10px',display:'flex'}}>

                                        <div className='selectTimeDate' style={{border:'1px solid',marginLeft:'20px',paddingLeft:'8px',minWidth:'120px'}} onClick={this.toggleDate}>
                                            {this.state.selectedDate}
                                            <img src={ArrowIcon} alt='Arrow' style={{width:'10px',height:'auto',marginLeft:'10px',marginBottom:'2px'}}/>
                                        </div>

                                        {
                                            this.state.dateOpt && 
                                            <div className='timeDrop' id='dateDrop' style={{minWidth:'120px',marginLeft:'20px'}}>
                                                <ul style={{ listStyle: 'none', padding: '10px',marginBottom:'0px',paddingTop:'5px'}}>
                                                    {optionsDate.map((date, index) => (
                                                        <li key={index} value={date} onClick={() => this.handleDropClickDate(date)}>
                                                            <div>{date}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }

                                        <div className='selectTimeDate' style={{width:'27%',border:'1px solid',marginLeft:'20px',paddingLeft:'8px'}} onClick={this.toggleTime}>
                                            {this.state.selectedTime}
                                            <img src={ArrowIcon} alt='Arrow' style={{width:'10px',height:'auto',marginLeft:'10px'}}/>
                                        </div>

                                        {
                                            this.state.timeOpt && 
                                            <div className='timeDrop' id='timeDrop' >
                                                <ul style={{ listStyle: 'none', padding: '10px',marginBottom:'0px',paddingTop:'5px'}}>
                                                    {optionsTime.map((time, index) => (
                                                        <li key={index} value={time} onClick={() => this.handleDropClick(time)}>
                                                            <div>{time}</div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                }

                                <hr></hr>

                            </div>

                            <div style={{position:'absolute',width:'70%',display:'inline-flex',justifyContent:'space-between',alignItems:'center',bottom:'40px',marginLeft:'30px'}}>
                                <div onClick={()=>{this.setState({guidanceBox:false})}} style={{color:'#757575',fontSize:'14px',display:'inline',cursor:'pointer',fontWeight:'700'}}>Cancel</div>

                                <div className='submitBtnHeader' onClick={() => this.handleSubmitClick()}>Submit</div>
                            </div>

                        </div>
                    </div>
                </Modal>

                {this.state.showThanks && <ThanksComponent handleThanksClose = {this.handleThanks}/>}

                {this.state.sendOTP && <SendOtpComponent handleSendOTPClose = {this.handleSendOtpClose}/>}

                {this.state.otpVer && <OtpVerComponent handleOtpClose = {this.handleOtpScreen}/>}
            </>
        );
    }
    

}


export default HeaderComponent;