import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import indifiLogo from '../../Images/lenderimages/Indifi_logo-01.png'
import iciciLogo from '../../Images/lenderimages/ICICI_Bank_Logo-01.jpg'
import offer from '../../Images/lenderimages/offer-svgrepo-com.svg'
import iiflLogo from '../../Images/lenderimages/IIFL-New-Common-logo.png'
import lendingkartLogo from '../../Images/lenderimages/lendingkartlogo-.svg'
import creditenableLogo from '../../Images/lenderimages/CreditEnable_Logo_Dark_Blue.png'
import andromedaLogo from '../../Images/lenderimages/Andromeda.jpeg(2)(1).jpg'
import psbLogo from '../../Images/lenderimages/PSB59-Logo-for-IndiaMart.png'
import pirimid from '../../Images/lenderimages/Ignosis_Logo_Horizontal.svg'
import creditmantriLogo from '../../Images/lenderimages/CM-Logo-1920.png'
import oneinfinityLogo from '../../Images/lenderimages/OI_logo.jpg'
import loantapLogo from '../../Images/lenderimages/HQ_LoanTap_logo_.png'
import poonawallaLogo from '../../Images/lenderimages/PFL-White-Logo.jpg'
import TataCapitalLogo from '../../Images/lenderimages/tataCapital.png'


const footerBoxStyle = {
  borderRadius: '24px',
  border: '1px solid #DCDCDC',
  background: '#FFF',
  padding: '20px',
  height: '310px',
  flexShrink: '0',
  marginTop: '50px',
  marginLeft: '166px',
  marginRight: '158px',
  marginBottom: '50px',
  overflow: 'hidden'
}

const headingStyle = {
  textAlign: 'center',
  fontFamily: 'Arial',
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '100%',
  textTransform: 'capitalize',
  background: 'linear-gradient(91deg, #1B56AC -5.48%, #28B1BE 46.43%)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}

const paraStyle = {
  color: '#4E4E4E',
  textAlign: 'center',
  fontFamily: 'Arial',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
};

const lendersBoxStyle = {
  borderRadius: '8px',
  border: '1px solid #D5EAFF',
  background: '#FFF',
  backgroundBlendMode: 'soft-light',
  boxShadow: '3px 6px 44px 0px rgba(46, 49, 146, 0.04)',
  height: '64px',
  flexShrink: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width:'93%',
}


const settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  centerMode : true
};


const linkStyle = {
  textDecoration: 'none',
  color: 'white',
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

class FooterComponent extends Component {
  render() {


    return (
      <>
        <section className="footer">
        
          <div style={{ background: '#49A399', height: '50px', display: 'grid', fontFamily:'arial' }}>
            <div className='container' style={{ width: '45%', alignSelf: 'center' }}>
              <div className='row'>
                <div className='col-3' style={{ padding: '0px' }}><a href="/about" rel="noopener noreferrer" style={linkStyle}>About us</a></div>
                <div className='col-4' style={{ padding: '0px' }}><a href="https://help.indiamart.com/" target="_blank" rel="noopener noreferrer" style={linkStyle}>Customer Care</a></div>
                <div className='col-5' style={{ padding: '0px' }}><a href="https://indiamart.com/" target="_blank" rel="noopener noreferrer" style={{...linkStyle,whiteSpace:'nowrap'}}>IndiaMART Home</a></div>
              </div>
            </div>
          </div>
          <div style={{ background: '#49A399', height:'35px', fontFamily:'arial' }}>
            <div className='container' style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '100%', paddingTop:'10px' }}>
              <div className='row' style={{ width: '38%', margin: '0px' }}>
                <div className='col-12' style={{ color: 'white', fontSize: '12px' }}>
                  1996-2024 IndiaMART.com. All rights reserved.
                </div>
              </div>

              <div className='row' style={{ width: '30%', margin: '0px' , fontFamily:'arial'}}>
                <div className='col-7' style={{ padding: '0px' }}>
                  <a href="https://www.indiamart.com/terms-of-use.html" target="_blank" rel="noopener noreferrer" style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                  }}>
                    Terms &amp; Conditions
                  </a>
                </div>
                <div className='col-5' style={{ padding: '0px' }}>
                  <a href="https://www.indiamart.com/privacy-policy.html" target="_blank" rel="noopener noreferrer" style={{
                    textDecoration: 'none',
                    color: 'white',
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                    paddingRight:'45px'
                  }}>
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section >
      </>
    )
  }
}

export default FooterComponent;
