// import React, { Component } from 'react'

// import HeaderComponent from '../header/HeaderComponent'
// import IIFLIntroComponent from '../lenderintro/IIFLIntroComponent'
// import IIFLProductComponent from '../products/IIFLProductComponent'
// import IIFLFAQsComponent from '../lenderFAQs/IIFLFAQsComponent'
// import FooterComponent from '../footer/FooterComponent'

// export class IIFLLenderPage extends Component {
//   render() {
//     return (
//       <>
//         <HeaderComponent/>
//         <IIFLIntroComponent/>
//         <IIFLProductComponent/>
//         <IIFLFAQsComponent/>
//         <FooterComponent/>
//       </>
//     )
//   }
// }

// export default IIFLLenderPage

import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader"; // Ensure correct import

const SpinnerExample = () => {
  const [loading, setLoading] = useState(false);

  const toggleLoading = () => {
    setLoading((prev) => !prev);
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <button
        onClick={toggleLoading}
        style={{
          padding: "10px 20px",
          backgroundColor: "#007BFF",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        {loading ? "Stop Loading" : "Start Loading"}
      </button>
      <div style={{ marginTop: "20px" }}>
        {loading && <ClipLoader color="#007BFF" size={50} />}
      </div>
    </div>
  );
};

export default SpinnerExample;