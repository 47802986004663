import React, { useState, useRef, useEffect, forwardRef, useContext, createContext } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid } from 'date-fns';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { checkLoginStatus, gatrack,getIST, getGstData, getGlid, identifyGLID, getcookieready, eventBus, setLoginStatCookie, fullLoginIds} from '../../utils';
import "../../CssFiles/newHomePage.css"
import Cookies from 'universal-cookie';
import { Modal } from 'react-bootstrap';

import OtpVerComponent from "../../Components/common/OtpVerComponent"

import BackgroundIcons from "../../Images/newHomeImages/formBgIcons.png"
import { useNewHomeContext } from '../pages/NewHomePage';
import PartnerCarouselComponent from '../partnerCarousel/partnerCarouselComponent';
import slant_arrow from '../../Images/newHomeImages/slant_arrow.png'
import SendOtpComponent from '../common/SendOtpComponent';
const like_gif = 'https://loans.imimg.com/images/loans/newHomeImages/like_gif.gif';
const guidance_icon = 'https://loans.imimg.com/images/loans/newHomeImages/guidance_icon.png'
const money_icon = 'https://loans.imimg.com/images/loans/newHomeImages/money_icon.png'
const low_roi_icon = 'https://loans.imimg.com/images/loans/newHomeImages/low_roi_icon.png'

const fullNameRegex = /^[a-zA-Z .]+$/;
const fullPanRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
const BusinessRegex = /^[a-zA-Z0-9. ]+$/;
const LoanContext = createContext();
const cookie = new Cookies();

const FormComponent = forwardRef((props, ref) => {
    const [token, setToken] = useState('');
    const [userInfo, setUserInfo] = useState({ bisName: '', name: '', pan: '', mob: '', updatedMob: '', glid: '', dob: '' })
    const [defaultUserInfo, setDefaultUserInfo] = useState({ bisName: '', name: '', pan: '', mob: '', updatedMob: '', glid: '', dob: '' })
    const [inputValue, setInputValue] = useState('');
    const [isValidName, setIsValidName] = useState(true);
    const [isValidPan, setIsValidPan] = useState(false);
    const [isValidMob, setIsValidMob] = useState(true);
    const [checkBoxWarning, setCheckBoxWarning] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [isChecked, setIsChecked] = useState(true);
    const [isValidDate, setIsValidDate] = useState(true);
    const [age, setAge] = useState(22);
    const [ageErr, setAgeErr] = useState(false);
    const [isValidBisName, setIsValidBisName] = useState(true);
    const [isClickCapture, setIsClickCapture] = useState(false);
    const [enteringPan, setEnteringPan] = useState(true);
    const [perPan, setPerPan] = useState(true);
    const loanValues = [1, 2, 5, 10, 20, 30, 40, 50];
    const [selectedLoanAmount, setSelectedLoanAmount] = useState(loanValues[2]);
    const [showOtpVer, setShowOtpVer] = useState(false);
    const [sendOtpModal, setSendOtpModal] = useState(false);
    const [showMobModal, setShowMobModal] = useState(false);
    const [mobErr1, setMobErr1] = useState(false);
    const [mobErr2, setMobErr2] = useState(false);
    const [inputFormTrigger, setInputFormTrigger] = useState(false);
    const [preventEdit, setPreventEdit] = useState(false);

    const dateInputRef = useRef(null);
    const datePickerRef = useRef(null);

    const setAllTrue = () => {
        setIsValidName(true);
        setIsValidPan(true);
        setIsValidMob(true);
        setPhoneError(true);
        setIsValidBisName(true);
        setEnteringPan(true);
        setPerPan(true);
      };

    const handleBusinessChange = (e) => {
        const value = e.target.value;
        setIsValidBisName(BusinessRegex.test(value));
        setUserInfo((prevInfo) => ({ ...prevInfo, bisName: value }));
    };

    const handleBusinessKey = (e) => {
        //console.log(e)
        if (BusinessRegex.test(e.key) || e.key === "Backspace" || e.key === "Delete" || e.key === ".") {
            return; 
        }
        e.preventDefault();
    }

    const handleNameKey = (e) => {
        if (fullNameRegex.test(e.key) || e.key === "Backspace" || e.key === "Delete") {
            return; 
        }
        e.preventDefault();
    }

    const handlePanKey = (e) => {
        const isAlphaNumeric = /^[a-zA-Z0-9]$/.test(e.key);

        if (!isAlphaNumeric && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
            e.preventDefault();
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        setIsValidName(fullNameRegex.test(value));
        setUserInfo((prevInfo) => ({ ...prevInfo, name: value }));
    };

    const checkValidPan = (value) => {
        if (value.length == 10 && /[A-Z]/.test(value.charAt(3)) && value.charAt(3) != 'P') {
            if (perPan) {
                setPerPan(false);
            }
        } else {
            if (!perPan) {
                setPerPan(true);
            }
        }

        if (value.length == 10 && value.charAt(3) == 'P' && fullPanRegex.test(value)) {
            if (!isValidPan) {
                setIsValidPan(true);
            }

            if (!enteringPan) {
                setEnteringPan(true)
            }
            return true;
        }
        if (isValidPan) {
            setIsValidPan(false);
        }
        if (enteringPan) {
            setEnteringPan(false)
        }
        return false;
    }

    const validatePanSubstring = (value) => {
        const length = value.length;

        if (length >= 4 && /[A-Z]/.test(value.charAt(3)) && value.charAt(3) !== 'P') {
            if (perPan) {
                setPerPan(false);
            }
            return false;
        } else {
            if (!perPan) {
                setPerPan(true);
            }
        }

        if (length === 1 || length === 2 || length === 3 || length === 4 || length == 5) {
            return /^[A-Z]{0,5}$/.test(value);
        }


        if (length >= 6 && length <= 9) {
            return /^[A-Z]{5}[0-9]{0,4}$/.test(value);
        }

        if (length === 10) {
            return /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(value);
        }

        return false; // Invalid length or condition
    };


    const handlePanChange = (e) => {
        const input = e.target;
        const cursorPosition = input.selectionStart;

        const value = e.target.value.trim().toUpperCase();
        const isValidSubstring = validatePanSubstring(value);

        setEnteringPan(isValidSubstring);

        if (value.length == 10) {
            checkValidPan(value);
        } else {
            if (isValidPan) {
                setIsValidPan(false);
            }
        }

        setUserInfo((prevInfo) => ({ ...prevInfo, pan: value }));


        input.setSelectionRange(cursorPosition, cursorPosition);
    };

    const handlePanInput = (e) => {
        const input = e.target;
        const start = input.selectionStart; // Capture the cursor position
        const end = input.selectionEnd;

        // Transform the input value to uppercase
        input.value = input.value.toUpperCase();

        // Restore the cursor position
        input.setSelectionRange(start, end);
    }

    const handleMobChange = (e) => {
        const value = e.target.value;
        setUserInfo((prevInfo) => ({ ...prevInfo, updatedMob: value }));
        const isValidMobile = /^[0-9]{0,10}$/.test(value);
        setIsValidMob(isValidMobile);
        if (phoneError) {
            setPhoneError(false);
        }
    };

    const handleDobChange = (date) => {
        if (date && isValid(date)) {

            let dateVal = format(date, 'dd-MM-yyyy')
            checkAge(dateVal);
            setUserInfo((prevInfo) => ({ ...prevInfo, dob: dateVal }));
            setInputValue(dateVal);
            if (!isValidDate) {
                setIsValidDate(true);
            }
        } else {
            setUserInfo((prevInfo) => ({ ...prevInfo, dob: "" }));
            setInputValue('');
        }
    };

    const handleDobInputChange = (e) => {
        let value = e.target.value;
        const cursorPosition = e.target.selectionStart;
        const keyPressed = e.nativeEvent.inputType;
        const isBackspace = keyPressed === 'deleteContentBackward';

        // Allow only digits and dashes
        const sanitizedValue = value.replace(/[^0-9-]/g, '');
        let formattedValue = sanitizedValue;

        if (isBackspace) {
            // Eat 5 Star.
        } else {
            // Handle auto-formatting for "dd-mm-yyyy"
            if (sanitizedValue.length === 2 && sanitizedValue[2] !== '-') {
                formattedValue = sanitizedValue.slice(0, 2) + '-' + sanitizedValue.slice(2);
            }
            if (sanitizedValue.length === 5 && sanitizedValue[5] !== '-') {
                formattedValue = sanitizedValue.slice(0, 5) + '-' + sanitizedValue.slice(5);
            }

            // Handle leading zeros and auto-adding dashes after single digits
            if ((keyPressed === 'insertText' && ['-', '/'].includes(e.nativeEvent.data)) || keyPressed === 'insertFromPaste') {
                const beforeCursor = sanitizedValue.slice(0, cursorPosition - 1);
                const afterCursor = sanitizedValue.slice(cursorPosition);
                if (beforeCursor.length === 1) {
                    formattedValue = '0' + beforeCursor + '-' + afterCursor;
                } else if (beforeCursor.length === 4) {
                    formattedValue = beforeCursor.slice(0, 3) + '0' + beforeCursor.slice(3) + '-' + afterCursor;
                }
            }

            // Prevent extra dashes from being added during re-entry
            if (sanitizedValue[cursorPosition - 1] === '-' && cursorPosition < sanitizedValue.length) {
                formattedValue = sanitizedValue;
            }
        }

        // Limit length to "dd-mm-yyyy"
        value = formattedValue.slice(0, 10);

        setInputValue(value);
        setUserInfo((prevInfo) => ({ ...prevInfo, dob: value }));

        // Adjust cursor position after formatting
        requestAnimationFrame(() => {
            const inputElement = e.target;
            let adjustedCursorPosition = cursorPosition;

            // Move cursor forward if a dash was added automatically
            if (!isBackspace && formattedValue.length > sanitizedValue.length) {
                if (cursorPosition === 2 || cursorPosition === 5) {
                    adjustedCursorPosition++;
                }
            }

            // Move cursor back if a dash was deleted
            if (isBackspace && formattedValue.length < sanitizedValue.length) {
                if (cursorPosition === 3 || cursorPosition === 6) {
                    adjustedCursorPosition--;
                }
            }

            inputElement.setSelectionRange(adjustedCursorPosition, adjustedCursorPosition);
        });

        const [dayStr = '', monthStr = '', yearStr = ''] = value.split('-');

        const dayStrLen = dayStr.length;
        const monthStrLen = monthStr.length;
        const yearStrLen = yearStr.length;

       // console.log("all length => ", dayStrLen, monthStrLen, yearStrLen)

        const [day, month, year] = value.split('-').map(Number);
        //console.log("Day in handleChange = >", day, "month => ", month);

        if ((dayStrLen == 0) || (day != undefined && dayStrLen >= 2 && (day > 31 || day < 1)) || (month != undefined && monthStrLen >= 2 && (month > 12 || month < 1)) || (year != undefined && yearStrLen >= 4 && (year < 1)) || ((dayStrLen == 0 || day == 0) && monthStrLen != 0) || ((monthStrLen == 0 || month == 0) && yearStrLen != 0) || (month == 2 && day > 29)) {
            setIsValidDate(false);
        } else {
            if (!isValidDate) {
                setIsValidDate(true);
            }
        }

        //if (year) { console.log("year length =>", year.toString().length, year) }

        //console.log("day month and year => ", day, month, year)
        if (day && month && year && yearStrLen == 4) {
            //console.log("In valid date checks ")
            if (checkValidDate(value)) {
                if (!isValidDate) {
                    setIsValidDate(true);
                }
            } else {
                if (isValidDate) {
                    setIsValidDate(false);
                }
            }

            if (checkAge(value)) {
                if (ageErr) {
                    setAgeErr(false);
                }
            } else {
                if (!ageErr) {
                    setAgeErr(true);
                }
            }

        } else {
            setAgeErr(false);
        }
    };

    const checkAge = (inputDate) => {
        //console.log("In check age");
        const today = new Date();
        const [day, month, year] = inputDate.split('-').map(Number);
        const birthDate = new Date(year, month - 1, day);

        let age = today.getFullYear() - birthDate.getFullYear();

        if (
            today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
        ) {
            age -= 1;
        }

       // console.log("Age is => ", age);
        setAge(age);

        if (age < 21 || age > 60) {
           // console.log("Setting less age to true");
            setAgeErr(true);
            return false;
        } else if (ageErr) {
            setAgeErr(false);
        }

        return true;
    };


    const checkValidDate = (inputDate) => {
        const today = new Date();
        const [day, month, year] = inputDate.split('-').map(Number);
        //console.log("Date in check Valid => ",day, month, year)

        const inputDateObject = new Date(year, month - 1, day); 

        if (isNaN(inputDateObject) || inputDateObject > today) {
            return false;
        }

        if(year == 0){
            return false;
        }

        if (month < 1 || month > 12) return false;

        if (day < 1 || day > 31) return false;

        const daysInMonth = new Date(year, month, 0).getDate();
        if (day > daysInMonth) return false;

        if (month === 2 && day > 29) {
            if ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)) {
                return true;
            }
            return false;
        }
       // console.log("returning true");
        return true;
    };


    const handleDobKeyDown = (e) => {
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '-', '/'];
        if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) {
            e.preventDefault();
        }
    }


    const handleCalendarClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);
        }
    };



    const handleMobKey = (e) => {
        if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'Enter') {
            e.preventDefault();
        }
    };

    const handleCheckClick = () => {
        setIsChecked(prevState => {
            const newState = !prevState;
           // console.log("setting isChecked to", newState);
            if (newState) {
                setCheckBoxWarning(false)
            }
            return newState;
        });
    }

    const checkPerPan = (pan) => {
        if (pan.charAt(3) != 'P') {
            return false;
        }
        return true;
    }


    const handleApplyClick = async () => {
        let valid = true;
        if (!userInfo.bisName || !isValidBisName) {
            setIsValidBisName(false);
            valid = false;
        }

        if (!userInfo.name || !isValidName) {
            setIsValidName(false);
            valid = false;
        }


        if (!checkValidPan(userInfo.pan)) {
            setIsValidPan(false);
            valid = false;
        }

        if (!isValidMob || userInfo.updatedMob.length != 10) {
            setIsValidMob(false);
            valid = false;
        }

        // if (!checkValidDate(userInfo.dob) || !checkAge(userInfo.dob)) {
        //     // console.log("DOB => ", userInfo.dob);
        //     // console.log("checkValid output => ", checkValidDate(userInfo.dob));
        //     valid = false;
        //     if (checkValidDate(userInfo.dob)) {
        //         setAgeErr(true);
        //     } else {
        //         setIsValidDate(false);
        //     }
        // }

        if (!isChecked) {
           // console.log("Please check the declaration checkbox, currently checkbox is => ", isChecked);
            valid = false;
            setCheckBoxWarning(true);
        }


        if (valid) {

            gatrack("Intent Capture", "Apply Now", "Click");
            var glid = getGlid();
            let loginStatus = await checkLoginStatus();

            if(loginStatus == "none"){
                try {
                    glid = await identifyGLID(userInfo.updatedMob);
                    if (!glid) {
                        console.error("Failed to retrieve GLID. Aborting.");
                    }
                } catch (error) {
                    console.error("Error during GLID identification:", error);
                }

                // if(fullLoginIds.has(glid)){
                    setShowOtpVer(true);
                    return;
                //}
                
            } else if(loginStatus == "partial"){
                setSendOtpModal(true);
                return;
            }


            //console.log("User Info => ", userInfo);
            const currentIST = getIST();
            var host = window.location.hostname;

            if (host == 'loans.indiamart.com') {
                var apiURL = "https://loans.indiamart.com/api/v1/captureClick";
            }
            else {
                var apiURL = "https://dev-loans.indiamart.com/api/v1/captureClick";
            }
            var encGlidCookie = cookie.get("encGlid");
            var inData = {
                "glid": glid,
                "encglid": encGlidCookie,
                "bname": userInfo.bisName,
                "iname": userInfo.name,
                "pan": userInfo.pan,
                "dob": userInfo.dob,
                "updatedMob": userInfo.updatedMob,
                "loanamt": selectedLoanAmount + " Lakh",
                "device":"Desktop",
                "landURL":window.location.href
            };

            var formData = new FormData();
            for (var key in inData) {
                formData.append(key, inData[key]);
            }

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-api-key': token },
                url: apiURL,
                data: formData,
                timeout: 5000
            }).then(function (response) {
                //console.log(response.data);
                if (response.data.CODE == "200") {
                    setIsClickCapture(true);
                }
            })
            //console.log("User Info => ", userInfo);
        } else {
            //console.log("Please ensure all fields are valid and the checkbox is checked.");
        }
    };

    const getMaskedMob = () => {
        // var glidEnd = getGlidEnd();

        var host = window.location.hostname;

        if (host == 'loans.indiamart.com') {
            var apiURL = "https://loans.indiamart.com/api/v1/getMaskedMob";
        }
        else {
            var apiURL = "https://dev-loans.indiamart.com/api/v1/getMaskedMob";
        }
        var glid = getGlid();
        var inData = {
            "glid": glid
        };

        var formData = new FormData();
        for (var key in inData) {
            formData.append(key, inData[key]);
        }

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: apiURL,
            data: formData,
            timeout: 2000
        }).then(function (response) {
           // console.log(response.data);
            if (response.data.CODE == "200") {
                var val = response.data.VALUE
                setUserInfo((prevInfo) => ({ ...prevInfo, updatedMob: val }));
            }
        })
    };

    const handleGst = async () => {
        const glid = getGlid();
        var gstNum = '', bisName = '', userName = '', pan = '';
        setUserInfo((prevInfo) => ({ ...prevInfo, glid: glid }));
        try {
            var data_obj = await getGstData(glid);
            //console.log("In gst call 2")
            if (data_obj && data_obj['code'] == '200') {
                if (data_obj['data']['values']) {

                    if (data_obj['data']['values']['business_name']);
                    {
                        userName = data_obj['data']['values']['business_name'];
                    }

                    if (data_obj['data']['values']['gstin_number']);
                    {
                        gstNum = data_obj['data']['values']['gstin_number'];
                        pan = await getPan(gstNum, userName);
                    }

                    if (data_obj['data']['values']['trade_name']);
                    {
                        bisName = data_obj['data']['values']['trade_name'];
                        setUserInfo((prevInfo) => ({ ...prevInfo, bisName: bisName }));
                        setDefaultUserInfo((prevInfo) => ({ ...prevInfo, bisName: bisName }));
                    }
                }
            }

        } catch (error) {
            console.error("An error occurred during gstapifunction execution from Form Component => ", error);
        }
        markLanding(glid, bisName, userName, pan);
    }

    const getPan = async(gstNum, userName) => {
        if (gstNum.length === 15) {
            const pan = gstNum.substring(2, 12).toUpperCase();
            if (pan.charAt(3) == 'P') {
               // console.log("Name in gst => ", userName)
               const loginStat = await checkLoginStatus();
                if (loginStat == "full"){
                    setUserInfo((prevInfo) => ({ ...prevInfo, pan: pan, name: userName }));
                    setDefaultUserInfo((prevInfo) => ({ ...prevInfo, pan: pan, name: userName }));
                } else {
                    console.log("User is non login, not setting pan");
                    setUserInfo((prevInfo) => ({ ...prevInfo, name: userName }));
                    setDefaultUserInfo((prevInfo) => ({ ...prevInfo, name: userName }));
                }
                checkValidPan(pan);
                return pan;
            } else {
               // console.log("User is Not Proprietor")
            }

        } else {
           // console.log('Invalid GST number');
        }
        return "";
    };

    const getToken = async (val) => {
        try {
            //console.log(val);

            const host = window.location.hostname;
            const apiURL =
                host === 'loans.indiamart.com'
                    ? "https://loans.indiamart.com/api/v1/getToken"
                    : "https://dev-loans.indiamart.com/api/v1/getToken";

            const inData = {
                "val": val
            };

            const formData = new FormData();
            for (const key in inData) {
                formData.append(key, inData[key]);
            }

            const response = await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: apiURL,
                data: formData,
                timeout: 2000
            });

            if (response.data?.CODE === "200") {
                const tk = response.data.VALUE;
                setToken(tk);
                return tk; // Return token value
            } else {
                console.error("Failed to fetch token. Response:", response.data);
                return null;
            }
        } catch (error) {
            console.error("An error occurred while fetching token:", error);
            return null;
        }
    };


    const markLanding = async (glid, bisName, userName, pan) => {
        try {
            // Wait for token value
            const tokenVal = await getToken(glid);

            if (!tokenVal) {
                console.error("Token value is null or undefined, cannot proceed with API call.");
                return;
            }

            const host = window.location.hostname;
            const apiURL =
                host === 'loans.indiamart.com'
                    ? "https://loans.indiamart.com/api/v1/captureLanding"
                    : "https://dev-loans.indiamart.com/api/v1/captureLanding";

            var encGlidCookie = cookie.get("encGlid");
            
            // Prepare input data
            const inData = {
                "glid": glid,
                "encglid": encGlidCookie,
                "bname": bisName,
                "iname": userName,
                "pan": pan,
                "dob": "",
                "updatedMob": "",
                "device":"Desktop",
                "landURL":window.location.href
            };

            // Convert input data to FormData
            const formData = new FormData();
            for (const key in inData) {
                formData.append(key, inData[key]);
            }

            // Make the API call
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'x-api-key': tokenVal
                },
                url: apiURL,
                data: formData,
                timeout: 2000
            });

            // Handle response
            if (response.data?.CODE === "200") {
               // console.log("API Response:", response.data);
            } else {
                console.error("Unexpected response from API:", response.data);
            }
        } catch (error) {
            console.error("An error occurred in markLanding:", error);
        }
    };

    const writeIntent = () =>{
        var host = window.location.hostname;
        var glid = getGlid();

            if (host == 'loans.indiamart.com') {
                var apiURL = "https://loans.indiamart.com/api/v1/captureClick";
            }
            else {
                var apiURL = "https://dev-loans.indiamart.com/api/v1/captureClick";
            }
            var encGlidCookie = cookie.get("encGlid");
            var inData = {
                "glid": glid,
                "encglid": encGlidCookie,
                "bname": userInfo.bisName,
                "iname": userInfo.name,
                "pan": userInfo.pan,
                "dob": userInfo.dob,
                "updatedMob": userInfo.updatedMob,
                "loanamt": selectedLoanAmount + " Lakh",
                "device":"Desktop",
                "landURL":window.location.href
            };

            var formData = new FormData();
            for (var key in inData) {
                formData.append(key, inData[key]);
            }

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'x-api-key': token },
                url: apiURL,
                data: formData,
                timeout: 5000
            }).then(function (response) {
                //console.log(response.data);
                if (response.data.CODE == "200") {
                    setIsClickCapture(true);
                }
            })
            //console.log("User Info => ", userInfo);
    }

    const handleSendOtpClose = (msg) => {
        //console.log("msg from sendOtp => ", msg);
        setSendOtpModal(false);
        if(msg == "send"){
            setShowOtpVer(true);
        } else if(msg == "diff"){
            setShowMobModal(true);
        } 
    }

    const handleOtpScreen = (msg) =>{
        if(msg == "ver"){
            setShowOtpVer(false);
            if(inputFormTrigger){
                setInputFormTrigger(false);
                if(preventEdit){
                    setPreventEdit(false);
                }
            } else{
                writeIntent();
            }
            
        } else if(msg == "diffUser"){
            setShowOtpVer(false);
            setShowMobModal(true);
            //console.log("Opening phone number modal in lender comp")
        }
        else{
            setShowOtpVer(false);
            //console.log("Closing otp screen as user clicked cross button or some error occured");
        }
    }

    const submitbtnClick = async() => {
        const phoneNumberInput = document.getElementById('phoneNumber').value;
        gatrack('Non Logged-in User','Submit Button Pressed','Click',phoneNumberInput);

        if(!phoneNumberInput)
        {
            setMobErr1(true);
            //console.log("Mob err1 true");
            if(mobErr2){
                setMobErr2(false);
            }
        }
        else if(phoneNumberInput.length != 10)
        {
            setMobErr1(true);
            //console.log("Mob err1 true");
            if(mobErr2){
                setMobErr2(false);
            }
        }
        else if(! ['6', '7', '8', '9'].includes(phoneNumberInput.charAt(0)))
        {
            setMobErr2(true);
           // console.log("Mob err2 true");
            if(mobErr1){
                setMobErr1(false);
            }
        }
        else{
            if(mobErr2){
                setMobErr2(false);
            }
            if(mobErr1){
                setMobErr1(false);
            }
            
            var host = window.location.hostname;

            if (phoneNumberInput == "7668945130" || phoneNumberInput == "9910524321") {
                if (host == 'loans.indiamart.com') {
                    var identifyurl = 'https://loans.indiamart.com/api/v1/getUser';
                }
                else {
                    var identifyurl = 'https://dev-loans.indiamart.com/api/v1/getUser';
                }
            } else {
                if(host == 'loans.indiamart.com'){
                    var identifyurl = 'https://loans.indiamart.com/functions.php';
                }
                else{
                    var identifyurl = 'https://dev-loans.indiamart.com/functions.php';
                }
            }

            //var identifyurl = 'https://localhost:8000/functions.php';

            var identifyData = {
                "functionName" :"identify",
                "mobile" : phoneNumberInput
            };

            var formData = new FormData();
            for (var key in identifyData) {
                formData.append(key, identifyData[key]);
            }

            var self = this;

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: identifyurl,
                data: formData,
                timeout:10000
              }).then(async (response) => {
                var data_obj = response['data'];

                
                if(data_obj.code == '200') {
                    
                    var glid = data_obj.DataCookie.glid;
                    var cookiestring = getcookieready(data_obj.DataCookie);

                    const expirationDate = new Date();
                    expirationDate.setDate(expirationDate.getDate() + 2);

                    const cookie = new Cookies();
                    if(host == 'loans.indiamart.com'){
                        cookie.set('ImeshVisitor', cookiestring, { path: '/',  domain: '.indiamart.com', expires: expirationDate });
                    } else{
                        cookie.set('ImeshVisitor', cookiestring, { path: '/', expires: expirationDate });
                    }
                    

                    setShowMobModal(false);
                    // if (fullLoginIds.has(glid)){
                        setShowOtpVer(true);
                    // } else{
                    //     setPreventEdit(false);
                    //     eventBus.emit("doneLogin", { message: "true" });
                    // }
                    
                    
                }
              })

        }    
    
    };

    const handleCloseClick = () => {
        if(setShowMobModal){
            setShowMobModal(false);
        }
    }

    const handleFormInputClick = async () => {
        const glid = getGlid();

        //if (!glid) {
            const cookie = new Cookies();
            const loginStatVal = cookie.get('loginStat');
            //console.log("Value from cookie => ", loginStatVal);
            if (loginStatVal != "true" && loginStatVal != true) {
                const loginStatus = await checkLoginStatus();

                if (loginStatus == "full") {
                    setLoginStatCookie("true");
                    if (preventEdit) {
                        setPreventEdit(false);
                    }
                } else if(loginStatus == "partial"){
                    setSendOtpModal(true);
                    setInputFormTrigger(true);
                    setPreventEdit(true);
                } else {
                    setShowOtpVer(true);
                    setInputFormTrigger(true);
                    setPreventEdit(true);
                }
            } else {
                if (preventEdit) {
                    setPreventEdit(false);
                }
            }
        //}
    }

    const handleDataFill = async()=>{
        let glid = getGlid();
        let loginStat = await checkLoginStatus();
        if((glid != undefined && glid != '' && glid != null) && (loginStat == "full")){
            handleGst();
            getMaskedMob();
        }
    }

    useEffect(() => {

        const handleDoneLogin = (data) => {
            //console.log("Received:", data.message);
            if(data.message == "true"){
                setAllTrue();
                setUserInfo({ bisName: '', name: '', pan: '', mob: '', updatedMob: '', glid: '', dob: '' });
                setDefaultUserInfo({ bisName: '', name: '', pan: '', mob: '', updatedMob: '', glid: '', dob: '' });
                handleGst();
                getMaskedMob();
                //console.log("Value of prevent Edit =>", preventEdit);
            }
        };

        // Subscribe to the event
        eventBus.subscribe("doneLogin", handleDoneLogin);

        // Cleanup on unmount
        return () => {
            eventBus.unsubscribe("doneLogin", handleDoneLogin);
        };
    }, []);


    return (
        <>
            <div className='form-comp'id='new-intent-form' ref={ref}>
                <div className='row' style={{width:'100%'}}>
                    <div className='col-6' >
                        <div style={{margin:'68px 50px 0px',minWidth:'286px',width:'100%'}}>
                            <div className='bg-text'>Your Business, Our Commitment: <br /> <span className='busi-grow'>The Right Loan, Every Time.</span>
                            <img src={slant_arrow} alt='Up-arrow' style={{width:'39px', height:'34px'}}/>
                            </div>
                            {/* <div><img src={BackgroundIcons} id='bg-icon' /></div> */}
                            {/* <div className='quick-line'>Quick, hassle-free loans designed to fuel your business growth with ease and confidence.</div> */}

                            <div className='one-stop'>A one stop shop for all your business loan needs</div>

                            <div style={{marginTop:'20px'}}>
                                <div className="loan-feature">
                                    <i className="bi bi-check-circle-fill feature-tick"></i>
                                    <div className='feature-text'>Inclusive Lending Solutions</div>
                                </div>
                                <div className="loan-feature">
                                    <i className="bi bi-check-circle-fill feature-tick"></i>
                                    <div className='feature-text'>Personalized Offers</div>
                                </div>
                                <div className="loan-feature">
                                    <i className="bi bi-check-circle-fill feature-tick"></i>
                                    <div className='feature-text'>Transparent and Supportive Process</div>
                                </div>
                            </div>

                            {/* <div className='white-boxes'>
                                <div>
                                    <div className='param-value'>100k+</div>
                                    <div className='param-text'>Happy Customer</div>
                                </div>

                                <div style={{border: '1px solid #D4D4D4',height:'67px',margin:'0px 16px'}}></div>

                                <div>
                                    <div className='param-value'>25000cr+</div>
                                    <div className='param-text'>Amount Disbursal</div>
                                </div>
                            </div> */}

                            <PartnerCarouselComponent/>

                            {/* <div className='scroll-benefit'>Scroll Down to see benefits <i class="bi bi-arrow-down" style={{marginLeft:'8px'}}></i></div> */}
                        </div>
                    </div>
                    <div className='col-6' style={{ justifyItems: 'center' }}>
                        <div className="form-container" style={{ width: isClickCapture ? '55%' : '61%', marginTop: isClickCapture ? '72px' : '52px', marginLeft:'75px' }}>
                            {
                                !isClickCapture &&
                                <>
                                    <div id='few-clicks' className='few-clicks'>Get easy loan in few clicks!</div>
                                    <form className="form">
                                        <div className="form-group" onClick={handleFormInputClick}>
                                            {/* <label>Business Name</label> */}
                                            <TextField
                                                variant="filled"
                                                label="Business Name"
                                                type="text"
                                                onChange={handleBusinessChange}
                                                onKeyDown={handleBusinessKey}
                                                inputProps={{ 
                                                    maxLength: 100,
                                                    readOnly: (defaultUserInfo.bisName.length > 0 && isValidBisName) || preventEdit,
                                                }}
                                                value={userInfo.bisName}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: defaultUserInfo.bisName.length > 0 && isValidBisName ? 'not-allowed' : 'inherit',
                                                    },
                                                    backgroundColor: !isValidBisName ? '#EB575726' : 'inherit',
                                                    '& .MuiFilledInput-underline:before': {
                                                        borderBottom: '1px solid #ddd',
                                                    },
                                                    '& .MuiFilledInput-underline:after': {
                                                        borderBottom: '1px solid #ddd',
                                                    },
                                                    '& .MuiFormLabel-root': {
                                                        transform: 'translate(12px, 14px) scale(1)', 
                                                        transition: 'transform 0.2s ease-in-out', 
                                                        color: '#7A7A7A',
                                                        fontSize:'15px'
                                                    },
                                                    '& .MuiInputLabel-shrink': {
                                                        transform: 'translate(11px, 2px) scale(0.75)',
                                                    },
                                                    '& .MuiFormLabel-root.Mui-focused': {
                                                        color: '#7A7A7A',
                                                    },
                                                    '& .MuiFilledInput-underline:hover:before': {
                                                        borderBottom: '1px solid #ddd', 
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        textAlign: 'left',
                                                        pointerEvents: defaultUserInfo.bisName.length > 0 && isValidBisName ? 'none' : 'auto', 
                                                    },
                                                }}
                                            />
                                            
                                            {!isValidBisName && <div className='err'>Please Enter Valid Business Name</div>}
                                        </div>

                                        <div className="form-group" onClick={handleFormInputClick}>
                                            {/* <label>Your Name</label> */}
                                            {/* <input type="text" placeholder="Enter Your Name" onChange={handleNameChange} onKeyDown={handleNameKey} maxLength={50} style={{
                                                ...(!isValidName && { backgroundColor: '#EB575726' }),
                                                ...(defaultUserInfo.name.length > 0 && isValidName && { cursor: 'not-allowed' }),
                                            }} defaultValue={defaultUserInfo.name} readOnly={defaultUserInfo.name.length > 0 && isValidName} /> */}

                                            <TextField
                                                variant="filled"
                                                label="Your Name"
                                                type="text"
                                                onChange={handleNameChange}
                                                onKeyDown={handleNameKey}
                                                inputProps={{ 
                                                    maxLength: 100,
                                                    readOnly: (defaultUserInfo.name.length > 0 && isValidName) || preventEdit,
                                                }}
                                                value={userInfo.name}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: defaultUserInfo.name.length > 0 && isValidName ? 'not-allowed' : 'inherit',
                                                    },
                                                    backgroundColor: !isValidName ? '#EB575726' : 'inherit',
                                                    '& .MuiFilledInput-underline:before': {
                                                        borderBottom: '1px solid #ddd',
                                                    },
                                                    '& .MuiFilledInput-underline:after': {
                                                        borderBottom: '1px solid #ddd',
                                                    },
                                                    '& .MuiFormLabel-root': {
                                                        transform: 'translate(12px, 14px) scale(1)', 
                                                        transition: 'transform 0.2s ease-in-out', 
                                                        color: '#7A7A7A',
                                                        fontSize:'15px'
                                                    },
                                                    '& .MuiInputLabel-shrink': {
                                                        transform: 'translate(11px, 2px) scale(0.75)',
                                                    },
                                                    '& .MuiFormLabel-root.Mui-focused': {
                                                        color: '#7A7A7A',
                                                    },
                                                    '& .MuiFilledInput-underline:hover:before': {
                                                        borderBottom: '1px solid #ddd', 
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        textAlign: 'left',
                                                        pointerEvents:defaultUserInfo.name.length > 0 && isValidName ? 'none' : 'auto', 
                                                    },
                                                }}
                                            />
                                            {!isValidName && <div className='err'>Please Enter Valid Name</div>}
                                        </div>

                                        <div className="form-group" onClick={handleFormInputClick}>
                                            {/* <label>Your PAN</label> */}
                                            <div className="input-with-icon">
                                                {/* <input type="text" maxLength={10} placeholder="Enter PAN" onChange={handlePanChange} onKeyDown={handlePanKey} onInput={handlePanInput} defaultValue={defaultUserInfo.pan} style={{
                                                    ...(!enteringPan && { backgroundColor: '#EB575726' }),
                                                    ...(defaultUserInfo.pan.length == 10 && isValidPan && { cursor: 'not-allowed' }),
                                                }}
                                                    readOnly={defaultUserInfo.pan.length == 10 && isValidPan} /> */}
                                                    <TextField
                                                        variant="filled"
                                                        label="Your PAN"
                                                        type="text"
                                                        onChange={handlePanChange}
                                                        onKeyDown={handlePanKey}
                                                        onInput={handlePanInput}
                                                        inputProps={{ 
                                                            maxLength: 10,
                                                            readOnly: (defaultUserInfo.pan.length > 0 && isValidPan) || preventEdit,
                                                        }}
                                                        value={userInfo.pan}
                                                        sx={{
                                                            width: '100%',
                                                            '& .MuiInputBase-root': {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            cursor: defaultUserInfo.pan.length > 0 && isValidPan ? 'not-allowed' : 'inherit',
                                                            backgroundColor:!enteringPan ? '#EB575726' : '#EEEEEE'
                                                            },
                                                            backgroundColor: !isValidPan ? '#EB575726' : '#EEEEEE',
                                                            '& .MuiFilledInput-underline:before': {
                                                                borderBottom: '1px solid #ddd',
                                                            },
                                                            '& .MuiFilledInput-underline:after': {
                                                                borderBottom: '1px solid #ddd',
                                                            },
                                                            '& .MuiFormLabel-root': {
                                                                transform: 'translate(12px, 14px) scale(1)', 
                                                                transition: 'transform 0.2s ease-in-out', 
                                                                color: '#7A7A7A',
                                                                fontSize:'15px'
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                transform: 'translate(11px, 2px) scale(0.75)',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-focused': {
                                                                color: '#7A7A7A',
                                                            },
                                                            '& .MuiFilledInput-underline:hover:before': {
                                                                borderBottom: '1px solid #ddd', 
                                                            },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'left',
                                                                pointerEvents:defaultUserInfo.pan.length > 0 && isValidPan ? 'none' : 'auto', 
                                                            },
                                                        }}
                                                    />
                                                {/* {isValidPan && <span className="tick-icon"><i className="bi bi-check-circle-fill"></i></span>} */}
                                                {!enteringPan && <span className="tick-icon" style={{ color: '#EB5757' }}><i className="bi bi-exclamation-triangle-fill"></i></span>}
                                            </div>
                                            {!enteringPan && perPan && <div className='err'>Please Enter Valid PAN No.</div>}
                                            {!perPan && <div className='err'>Please Enter Personal PAN No.</div>}
                                        </div>

                                        <div className="form-group" onClick={handleFormInputClick}>
                                            {/* <label>Phone</label> */}
                                            <div className="phone-input">
                                                {/* <input type="text" placeholder="Enter Phone Number" onChange={handleMobChange} onKeyDown={handleMobKey} maxLength={10} value={userInfo.updatedMob} style={{
                                                    ...(isValidMob ? {} : { backgroundColor: '#EB575726' }),
                                                    letterSpacing: '1px',
                                                }} /> */}

                                                    <TextField
                                                        variant="filled"
                                                        label="Enter Phone Number"
                                                        type="text"
                                                        onChange={handleMobChange}
                                                        onKeyDown={handleMobKey}
                                                        inputProps={{ 
                                                            maxLength: 10,
                                                            readOnly:preventEdit
                                                        }}
                                                        value={userInfo.updatedMob}
                                                        sx={{
                                                            width: '100%',
                                                                '& .MuiInputBase-root': {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                letterSpacing:'1px'
                                                            },
                                                            backgroundColor: !isValidMob ? '#EB575726' : 'inherit',
                                                            '& .MuiFilledInput-underline:before': {
                                                                borderBottom: '1px solid #ddd',
                                                            },
                                                            '& .MuiFilledInput-underline:after': {
                                                                borderBottom: '1px solid #ddd',
                                                            },
                                                            '& .MuiFormLabel-root': {
                                                                transform: 'translate(12px, 14px) scale(1)', 
                                                                transition: 'transform 0.2s ease-in-out', 
                                                                color: '#7A7A7A',
                                                                fontSize:'15px'
                                                            },
                                                            '& .MuiInputLabel-shrink': {
                                                                transform: 'translate(11px, 2px) scale(0.75)',
                                                            },
                                                            '& .MuiFormLabel-root.Mui-focused': {
                                                                color: '#7A7A7A',
                                                            },
                                                            '& .MuiFilledInput-underline:hover:before': {
                                                                borderBottom: '1px solid #ddd', 
                                                            },
                                                        }}
                                                    />
                                            </div>
                                            {!isValidMob && <div className='err'>Please Enter Valid Phone No.</div>}
                                        </div>

                                        <LoanContext.Provider value={{ selectedLoanAmount, setSelectedLoanAmount }}>
                                            <div>
                                                {/* <h1>Selected Loan Amount: {selectedLoanAmount} Lakh</h1> */}
                                                {/* <LoanRangeSlider /> */}
                                            </div>
                                        </LoanContext.Provider>
                                        <div className="form-group checkbox-group">
                                            {
                                                !isClickCapture &&
                                                <>
                                                    <div className="checkbox-container">
                                                        <div className="checkbox-container" onChange={handleCheckClick}>
                                                            <input type="checkbox" id="custom-checkbox" defaultChecked />
                                                            <label htmlFor="custom-checkbox"></label>
                                                        </div>
                                                    </div>
                                                    <label htmlFor="declaration" className='declaration' style={{ marginBottom: '0px' }}>
                                                        I agree to IndiaMART's Privacy Policy and T&Cs.
                                                    </label>
                                                </>
                                            }
                                            {
                                                isClickCapture &&
                                                <div htmlFor="declaration" style={{ marginBottom: '0px', fontSize: '16px', fontFamily: 'Arial', color: '#007A6E' }}>
                                                    Thanks for applying. Someone from our team will reach out to you.
                                                </div>
                                            }

                                        </div>
                                        {!isChecked && <div className='err' style={{ marginTop: '-13px', marginBottom: '10px' }}>Please accept the Terms & Conditions by selecting the checkbox</div>}

                                        {
                                            !isClickCapture &&
                                            <div className="submit-btn" onClick={handleApplyClick}>Apply Now</div>
                                        }
                                    </form>
                                </>
                            }

                            {
                                isClickCapture &&
                                <div id='click-captured' style={{ position: 'relative' }}>
                                    <div className="left-circle"></div>
                                    <div className="right-circle"></div>

                                    <div style={{ paddingTop: '30px' }}>
                                        <div style={{ width: '70%', margin: '0 auto', textAlign: 'center' }}>
                                            <img src={like_gif} alt='like' style={{ width: '77px', height: 'auto' }} />
                                            <div className='thanksText'>Thank You for applying!</div>
                                            <div className='reach-out'>Our team will reach out to you in next 6 hours</div>
                                        </div>
                                    </div>

                                    <div className='three-offers-box'>
                                        <div className='what-we-offer'>What We Offer</div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px auto 10px' }}>
                                            <div className='offer-boxes'>
                                                <img src={money_icon} alt='money' className='offer-icon' />
                                                <div className='offer-text'>High<br /> Amount</div>
                                            </div>

                                            <div className='offer-boxes'>
                                                <img src={low_roi_icon} alt='low-roi' className='offer-icon' />
                                                <div className='offer-text'>Low<br /> ROI</div>
                                            </div>

                                            <div className='offer-boxes'>
                                                <img src={guidance_icon} alt='guidance' className='offer-icon' />
                                                <div className='offer-text'>Expert<br /> Guidance</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            {
                sendOtpModal && <SendOtpComponent handleSendOTPClose={handleSendOtpClose}/>
            }

            {showOtpVer && 
                <OtpVerComponent handleOtpClose = {handleOtpScreen}/>
            }

            <Modal

                show={showMobModal}
                backdrop="static"
                keyboard={false}
                id="loginpopup" style={{ marginTop: '200px' }}>

                <Modal.Header style={{ backgroundColor: '#007A6E', padding: '8px 16px' }}>
                    <Modal.Title className="fs-5" style={{ color: '#ffff', fontWeight: '700', fontSize: '20px !important' }} >Sign In</Modal.Title>
                    <button type="button" className="custom-close-button" onClick={handleCloseClick} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: '0', position: 'absolute', top: '2px', right: '16px' }}>
                        &times;
                    </button>
                </Modal.Header>
                <Modal.Body >
                    <label htmlFor="phoneNumber" style={{ marginLeft: '3px', marginBottom: '10px' }}>Mobile Number linked to IndiaMART account</label>
                    <input type="text" maxLength="10" placeholder="Enter your IndiaMART account mobile number" id="phoneNumber" className="un2_s" style={{ backgroundColor: 'rgb(255, 255, 255)', outline: 'none', boxSizing: 'content-box', paddingLeft: '5px', width: '97%', height: '30px', margin: 'auto', letterSpacing: '0px', fontSize: '14px' }} pattern="\d{10}" required onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')} />
                    <span id="mobile_err" className="em-1" style={{ display: 'none', fontSize: '12px', marginLeft: '3px' }}>Please enter mobile number</span>
                    {mobErr1 && <span id="mobile_err2" className="em-1" style={{fontSize: '12px', marginLeft: '3px' }}>Please enter ten digit mobile number</span>}
                    {mobErr2 && <span id="mobile_err3" className="em-1" style={{ fontSize: '12px', marginLeft: '3px' }}>Mobile Number should start with 6,7,8 or 9</span>}
                    <button id="submitBtn" className="continue_s" style={{ padding: '8px 0px', width: '180px', backgroundColor: '#007A6E', margin: '15px auto', display: 'block', marginBottom: '5px' }} onClick={submitbtnClick} >Submit</button>

                </Modal.Body>


            </Modal>
        </>
    )
})

const LoanRangeSlider = () => {
    const loanValues = [1, 2, 5, 10, 20, 30, 40, 50];
    const { selectedLoanAmount, setSelectedLoanAmount } = useContext(LoanContext);

    const handleRangeChange = (event) => {
        const index = parseInt(event.target.value, 10);
        setSelectedLoanAmount(loanValues[index]); // Update the context with the selected value
    };

    return (
    <>
        <div className="form-group">
            <label htmlFor="loanRange" style={{ display: 'inline' }}>Loan Amount:</label>
            <span style={{ float: 'right', fontWeight: '700' }}>{selectedLoanAmount} Lakh</span>

            <input
                type="range"
                id="loanRange"
                min="0"
                max={loanValues.length - 1}
                step="1"
                value={loanValues.indexOf(selectedLoanAmount)} // Set the value based on the current loan amount
                onChange={handleRangeChange}
                className="range_elem"
            />
            <div>
                <span style={{ marginRight: 'auto' }}>1 Lakh</span>
                <span style={{ float: 'right' }}>50 Lakh</span>
            </div>
        </div>
    </>
    );
};

export default FormComponent
