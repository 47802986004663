import React from 'react'

import "../../CssFiles/thanks.css"

import safetyIcon from "../../Images/commonIcons/safetyIcon.png"
import timeIcon from "../../Images/commonIcons/timeIcon.gif"

function ThanksComponent({handleThanksClose}) {

    return (
        <>
            <div className='pi thanks'>
                <div onClick={()=>handleThanksClose()} className="close-btn">
                    ×
                </div>
                <div className='text-parent'>
                    <div><img src={timeIcon} style={{ width: '85px' }} /></div>
                    <div id='text-1'>Thank you 🚀</div>
                    <div id='text-2'>Your callback request has been successfully registered. Our representative will reach out to you shortly</div>
                </div>

                <div className='safety'>
                    <img src={safetyIcon} />
                    <div id='safety-text'>Your data is 100% safe & secure</div>
                </div>
            </div>
        </>
    )
}

export default ThanksComponent