import React from 'react'

import Modal from 'react-bootstrap/Modal';

const orStyles = {
    orContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "20px 0",
    },
    line: {
        flex: 1,
        height: "1px",
        with:"50%",
        backgroundColor: "grey",
        margin: "0 10px", 
    },
    orText: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "grey",
        textTransform: "uppercase",
    },
};

function SendOtpComponent({ handleSendOTPClose }) {

    const handleScreenEvent = (msg) => {
        if (msg == "send") {
            handleSendOTPClose("send")
        } else if (msg == "diff") {
            handleSendOTPClose("diff")
        } else {
            handleSendOTPClose("close")
        }
    }
    return (
        <>
            <Modal

                show={true}
                backdrop="static"
                keyboard={false}
                id="loginpopup" style={{ marginTop: '200px' }}>

                <Modal.Header style={{ backgroundColor: '#007A6E', padding: '8px 16px' }}>
                    <Modal.Title className="fs-5" style={{ color: '#ffff', fontWeight: '700', fontSize: '20px !important' }} >Sign In</Modal.Title>
                    <button type="button" className="custom-close-button" onClick={() => handleScreenEvent("close")} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: '0', position: 'absolute', top: '2px', right: '16px' }}>
                        &times;
                    </button>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center' }}>

                    <div>
                        Click below to get One Time Password (OTP)<br />on your mobile
                    </div>

                    <div onClick={() => handleScreenEvent("send")} style={{ backgroundColor: '#007A6E', margin: '10px auto', color: '#FFF', fontWeight: '700', width: '50%', alignContent: 'center', borderRadius: '6px', height: '40px', cursor:'pointer' }}>Send OTP</div>

                    <div style={orStyles.orContainer}>
                        <span style={orStyles.line}></span>
                        <span style={orStyles.orText}>OR</span>
                        <span style={orStyles.line}></span>
                    </div>


                    <p id='diff-user' style={{ color: '615d5d', fontSize: '11px', textDecoration: 'underline', cursor: 'pointer', marginBottom:'0px' }} onClick={() => handleScreenEvent("diff")}>Sign In as Different User</p>
                </Modal.Body>


            </Modal>
        </>
    )
}

export default SendOtpComponent